import { Component, OnInit, OnDestroy } from '@angular/core';
import { ICalendarMonth, IInspectionListItem, InputConverterService, InspectionService, IError } from 'ee-common-lib';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
	selector: 'app-upcoming-inspections',
	templateUrl: './upcoming-inspections.component.html',
	styleUrls: ['./upcoming-inspections.component.scss']
})
export class UpcomingInspectionsComponent implements OnInit, OnDestroy {

	public loading: boolean;
	public currentMonth: ICalendarMonth;
	public inspections: IInspectionListItem[];

	private _routeParamsSub: Subscription;
	private _inspectionsSub: Subscription;

	constructor(
		private _route: ActivatedRoute,
		private _router: Router,
		private _inspectionService: InspectionService) {}

	ngOnInit() {
		this.loading = true;
		this._routeParamsSub = this._route.paramMap.subscribe(params => {
			if (params.has('month') && params.has('year')) {
				const year = InputConverterService.parseInt(params.get('year'));
				const month = InputConverterService.parseInt(params.get('month'));

				this.currentMonth = {
					month,
					year
				};

				this.refreshInspections();
			} else {
				this.onCurrentMonthChanged({
					month: InputConverterService.getCurrentMonth(),
					year: InputConverterService.getCurrentYear()
				});
			}
		});
	}

	ngOnDestroy() {
		this._routeParamsSub.unsubscribe();
		this.cancelRefreshInspections();
	}

	public refreshInspections() {
		this.cancelRefreshInspections();
		this.loading = true;

		this._inspectionsSub = this._inspectionService.getScheduledInspectionsListByMonth(
			this.currentMonth.year, this.currentMonth.month).subscribe({
				next: (inspections: IInspectionListItem[]) => {
					this.inspections = inspections;
					this.loading = false;
				},
				error: (error: IError) => {
					this.loading = false;
				}
			});
	}

	private cancelRefreshInspections() {
		if (this._inspectionsSub != null) {
			this._inspectionsSub.unsubscribe();
			this._inspectionsSub = null;
		}
	}

	public onCurrentMonthChanged(newMonth: ICalendarMonth) {
		this.currentMonth = {
			year: newMonth.year,
			month: newMonth.month
		};

		this._router.navigate(['inspections', 'upcoming', newMonth.year, newMonth.month]);
	}
}
