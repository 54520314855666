import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import {
	AuthGuard, ChangePasswordComponent, DEFAULT_INSPECTION_SEARCH_DAYS_BEFORE, InspectionResolverService,
	WellResolverService } from 'ee-common-lib';
import { HomeComponent } from './home/home.component';
import { CustomerPortalComponent } from './customer-portal/customer-portal.component';
import { ContactComponent } from './contact/contact.component';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { RoutesOverviewComponent } from './routes-overview/routes-overview.component';
import { WellOverviewComponent } from './well-overview/well-overview.component';
import { UpcomingInspectionsComponent } from './upcoming-inspections/upcoming-inspections.component';
import { WellInspectionsPageResolverService } from './well-inspections-page-resolver.service';
import { InspectionSearchComponent } from './inspection-search/inspection-search.component';

const routes: Routes = [
	{
		path: 'login',
		component: CustomerPortalComponent
	},
	{
		path: '',
		component: HomeComponent,
		canActivate: [AuthGuard]
	},
	{
		path: 'profile',
		component: UserProfileComponent,
		canActivate: [AuthGuard]
	},
	{
		path: 'profile/changePassword',
		component: ChangePasswordComponent,
		canActivate: [AuthGuard]
	},
	{
		path: 'contact',
		component: ContactComponent,
		canActivate: [AuthGuard]
	},
	{
		path: 'routes',
		component: RoutesOverviewComponent,
		canActivate: [AuthGuard]
	},
	{
		path: 'routes/:routeID/wells/:wellID',
		component: WellOverviewComponent,
		canActivate: [AuthGuard],
		resolve: {
			well: WellResolverService,
			page: WellInspectionsPageResolverService
		}
	},
	{
		path: 'inspections/upcoming',
		canActivate: [AuthGuard],
		component: UpcomingInspectionsComponent
	},
	{
		path: 'inspections/upcoming/:year/:month',
		canActivate: [AuthGuard],
		component: UpcomingInspectionsComponent
	},
	{
		path: 'inspections/search',
		redirectTo: `inspections/search/${DEFAULT_INSPECTION_SEARCH_DAYS_BEFORE}`,
		pathMatch: 'full'
	},
	{
		path: 'inspections/search/:numDays',
		component: InspectionSearchComponent,
		canActivate: [AuthGuard]
	}
];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule]
})
export class AppRoutingModule { }
