import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { WellService, IInspection, IError } from 'ee-common-lib';

export interface IInspectionRequestedEvent {
	wellID: number;
	wellName: string;
	inspection: IInspection;
	inspectionID: number,
	followUpRequested: boolean;
	numMonthsFromLastInspection: number;
}

export interface IInspectionRequestFailedEvent {
	wellID: number;
	wellName: string;
	inspectionID: number,
	followUpRequested: boolean;
	numMonthsFromLastInspection: number;
	error: IError;
}

@Component({
	selector: 'app-request-inspection-button',
	templateUrl: './request-inspection-button.component.html',
	styleUrls: ['./request-inspection-button.component.scss']
})
export class RequestInspectionButtonComponent implements OnInit {
	@Input() wellID: number;
	@Input() inspectionID: number;
	@Input() wellName: string;
	@Input() lastInspectionID: number;
	@Input() lastInspectionDate: Date;

	@Output() inspectionRequested: EventEmitter<IInspectionRequestedEvent>;
	@Output() inspectionRequestFailed: EventEmitter<IInspectionRequestFailedEvent>;

	public monthsList: number[];
	public requestingInspection: boolean;

	public readonly NO_FOLLOW_UP_REQUESTED = 0;

	constructor(private _wellService: WellService) {
		this.monthsList = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
		this.inspectionRequested = new EventEmitter<IInspectionRequestedEvent>();
		this.inspectionRequestFailed = new EventEmitter<IInspectionRequestFailedEvent>();
	}

	ngOnInit() {
	}

	public getMonthText(numMonths: number) {
		return `${numMonths} ${numMonths === 1 ? 'month' : 'months'}`;
	}

	public onRequestInspection(numMonthsFromLastInspection: number) {
		const followUpRequested = (numMonthsFromLastInspection !== this.NO_FOLLOW_UP_REQUESTED);

		let confirmMsg = '';
		if (followUpRequested) {
			const monthText = this.getMonthText(numMonthsFromLastInspection);
			confirmMsg = `Request follow-up ${monthText} from the last inspection for ${this.wellName}?`;
		} else {
			numMonthsFromLastInspection = null;
			confirmMsg = `Request no follow-up for ${this.wellName}?`;
		}


		if (confirm(confirmMsg)) {
			this.requestingInspection = true;
			this._wellService.requestInspection(this.wellID, followUpRequested, numMonthsFromLastInspection, this.inspectionID)
				.subscribe({
					next: (inspection: IInspection) => {
						this.requestingInspection = false;
						this.inspectionRequested.emit({
							wellID: this.wellID,
							wellName: this.wellName,
							inspection,
							inspectionID: this.inspectionID,
							followUpRequested,
							numMonthsFromLastInspection
						});
					},
					error: (error: IError) => {
						this.requestingInspection = false;
						this.inspectionRequestFailed.emit({
							error,
							wellID: this.wellID,
							wellName: this.wellName,
							inspectionID: this.inspectionID,
							followUpRequested,
							numMonthsFromLastInspection
						});
					}
				});
		}
	}
}
