import { Component, Inject } from '@angular/core';
import { NgbPaginationConfig } from '@ng-bootstrap/ng-bootstrap';
import { APP_CONFIG, IAppConfig } from 'ee-common-lib';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})
export class AppComponent {
	constructor(
		private _paginationService: NgbPaginationConfig,
		@Inject(APP_CONFIG) private _appConfig: IAppConfig) {
		this._paginationService.maxSize = this._appConfig.maxNumPages;

	}

	onActivate(event) {
		// window.scroll(0,0);

		window.scroll({
			top: 0,
			left: 0,
			behavior: 'smooth'
		});

    //or document.body.scrollTop = 0;
    //or document.querySelector('body').scrollTo(0,0)
    
	}
}
