import { Component, OnInit, OnDestroy, EventEmitter, Output } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
	selector: 'app-sidebar',
	templateUrl: './sidebar.component.html',
	styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit, OnDestroy {
	public showRoutesNav: boolean;
	
	private _routeChangedSubscription: Subscription;
	constructor(private _router: Router) {
		this.showRoutesNav = false;
	}

	private onRouteChanged = (event: NavigationEnd) => {
		if (event instanceof NavigationEnd) {
			this.showRoutesNav = event.url.indexOf('/routes') >= 0;
		}
	}

	ngOnInit() {
		this._routeChangedSubscription = this._router.events.subscribe(this.onRouteChanged);
	}

	ngOnDestroy() {
		this._routeChangedSubscription.unsubscribe();
	}

	

}
