import { Component, OnInit, OnDestroy } from '@angular/core';

import { RouteTreeService, IRouteTree, UserService, ICustomerRep } from 'ee-common-lib';
import { Subscription } from 'rxjs';

@Component({
	selector: 'app-routes-overview',
	templateUrl: './routes-overview.component.html',
	styleUrls: ['./routes-overview.component.scss']
})
export class RoutesOverviewComponent implements OnInit, OnDestroy {
	public routes: IRouteTree[];
	public loading: boolean;

	private _currentUserSubscription: Subscription;
	constructor(private _routesService: RouteTreeService, private _userService: UserService) {
		this.loading = false;
	}

	private onCustomerRepChanged = (customerRep: ICustomerRep): void => {
		const customerID = customerRep != null ? customerRep.CustomerID : null;
		this.fetchRoutes(customerID);
	}

	private fetchRoutes(customerID: number) {
		this.routes = [];

		if (customerID == null) {
			return;
		}

		this.loading = true;
		this._routesService.getTree(customerID).subscribe({
			next: (routes) => {
				this.routes = routes;
				this.loading = false;
			},
			error: (err) => {
				this.loading = false;
			}
		});
	}

	ngOnInit() {
		this._currentUserSubscription = this._userService.currentUser$.subscribe(this.onCustomerRepChanged);
	}

	ngOnDestroy() {
		this._currentUserSubscription.unsubscribe();
	}

	public getTrackByID(route: IRouteTree): number {
		return route.RouteID;
	}

}
