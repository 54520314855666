import { Component, OnInit, OnDestroy } from '@angular/core';
import { UserService, SharedAppSettings, InputConverterService, ICustomerRep } from 'ee-common-lib';
import { Subscription } from 'rxjs';

@Component({
	selector: 'app-navbar',
	templateUrl: './navbar.component.html',
	styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit, OnDestroy {
	public userFullName: string;
	public userLoginID: string;
	public eeWebsiteUrl: string;
	public userIsAuthorized: boolean;
	public customerLogoAssetPath: string;
	public customerName: string;

	private _currentUserSubscription: Subscription;
	constructor(private _userService: UserService) {
		this.eeWebsiteUrl = SharedAppSettings.eeWebsiteUrl;
	}

	ngOnInit() {
		this._currentUserSubscription = this._userService.currentUser$.subscribe((currentUser: ICustomerRep) => {
			this.userIsAuthorized = this._userService.currentUserIsAuthorized();

			this.userFullName = currentUser != null ? currentUser.Name : '';
			this.userLoginID = currentUser != null ? currentUser.LoginID : '';
			this.customerLogoAssetPath = currentUser != null ? currentUser.CustomerLogoAssetPath : '';
			this.customerName = currentUser != null ? currentUser.CustomerName : '';
		});
	}

	ngOnDestroy() {
		this._currentUserSubscription.unsubscribe();
	}
}
