import { Component, OnInit, OnDestroy } from '@angular/core';
import { NgbAccordion } from '@ng-bootstrap/ng-bootstrap';
import { RouteTreeService, UserService, ICustomerRep, IRouteTree } from 'ee-common-lib';
import { Subscription } from 'rxjs';

@Component({
	selector: 'app-routes-nav',
	templateUrl: './routes-nav.component.html',
	styleUrls: ['./routes-nav.component.scss']
})
export class RoutesNavComponent implements OnInit, OnDestroy {
	public customerRoutes: IRouteTree[];
	public loading: boolean;
	public selectedRouteID: number;
	public routesAcc: NgbAccordion;

	private _currentUserSubscription: Subscription;
	private _currentRouteSubscription: Subscription;

	constructor(private _routesService: RouteTreeService, private _userService: UserService) {
	}

	private onSelectedRouteIDChanged = (routeID: number) => {
		this.selectedRouteID = routeID;
	}

	private onCustomerRepChanged = (customerRep: ICustomerRep) => {
		const customerID = customerRep != null ? customerRep.CustomerID : null;
		this._routesService.setSelectedCustomerID(customerID);
		this.fetchCustomerRoutes(customerID);
	}

	private fetchCustomerRoutes(customerID: number): void {
		this.customerRoutes = [];

		if (customerID == null) {
			return;
		}

		this.loading = true;

		this._routesService.getTree(customerID).subscribe({
			next: (result: IRouteTree[]) => {
				this.customerRoutes = result;
				this.loading = false;
			},
			error: (err) => {
				this.loading = false;
			}
		});
	}

	ngOnInit() {
		this._currentUserSubscription = this._userService.currentUser$.subscribe(this.onCustomerRepChanged);
		this._currentRouteSubscription = this._routesService.selectedRouteID$.subscribe(this.onSelectedRouteIDChanged);
	}

	ngOnDestroy() {
		this._currentUserSubscription.unsubscribe();
		this._currentRouteSubscription.unsubscribe();
	}
}
