import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-customer-portal',
	templateUrl: './customer-portal.component.html',
	styleUrls: ['./customer-portal.component.scss']
})
export class CustomerPortalComponent implements OnInit {

	constructor() { }

	ngOnInit() {
	}

}
