import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { CUSTOMER_APP_CONFIG } from './app.config';
import { CUSTOMER_AUTH_CONFIG } from './auth.config';

import { APP_CONFIG, AUTH_CONFIG, EeCommonLibModule, JwtInterceptor, ErrorInterceptor } from 'ee-common-lib';

import { HomeComponent } from './home/home.component';
import { NavbarComponent } from './navbar/navbar.component';
import { RoutesNavComponent } from './routes-nav/routes-nav.component';
import { WellOverviewComponent } from './well-overview/well-overview.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { RoutesOverviewComponent } from './routes-overview/routes-overview.component';
import { ContactComponent } from './contact/contact.component';
import { CustomerPortalComponent } from './customer-portal/customer-portal.component';
import { ContactInfoComponent } from './contact-info/contact-info.component';
import { RecentInspectionsComponent } from './recent-inspections/recent-inspections.component';
import { RequestInspectionButtonComponent } from './request-inspection-button/request-inspection-button.component';
import { UpcomingInspectionsComponent } from './upcoming-inspections/upcoming-inspections.component';
import { InspectionSearchComponent } from './inspection-search/inspection-search.component';

@NgModule({
	declarations: [
		AppComponent,
		HomeComponent,
		NavbarComponent,
		RoutesNavComponent,
		WellOverviewComponent,
		SidebarComponent,
		UserProfileComponent,
		RoutesOverviewComponent,
		ContactComponent,
		CustomerPortalComponent,
		ContactInfoComponent,
		RecentInspectionsComponent,
		RequestInspectionButtonComponent,
		UpcomingInspectionsComponent,
		InspectionSearchComponent
	],
	imports: [
		BrowserModule,
		AppRoutingModule,
		NgbModule,
		EeCommonLibModule,
		HttpClientModule
	],
	providers: [
		{
			provide: APP_CONFIG,
			useValue: CUSTOMER_APP_CONFIG
		},
		{
			provide: AUTH_CONFIG,
			useValue: CUSTOMER_AUTH_CONFIG
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: JwtInterceptor,
			multi: true
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: ErrorInterceptor,
			multi: true
		}
	],
	bootstrap: [AppComponent]
})
export class AppModule { }
